import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`We will have classes today at 9:00 & 10:00am only at The Ville!`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today The Ville will be hosting a fundraising wod for the family of
LMPD Officer Jason Schweitzer who was tragically killed by a drunk
driver in Lexington, Ky.  You can donate and purchase a shirt at the
link below.  CrossFit 222 will show up as the host but you can choose
your shirt size and our location when checking out.  You will also be
able to donate today at The Ville.  Please sign up and show your support
for one of our LMPD officers and his family!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`Full RX Workout: (For Athletes who have been doing CrossFit for a
minimum of 6 months)`}</strong></p>
    <p>{`AMRAP 37:\\
69 Double Unders\\
4 Rope Climbs (15′)`}{`[\\
10 Clean & Jerks (135/95)\\
29 Wall Balls (20/14-10’/9′)\\
20 T2B\\
16 Burpee Box Jump Overs (24/20)]`}{`{.text_exposed_show}`}</p>
    <p><strong parentName="p">{`Scaled Option 1: (This is a Scaled Option for CrossFit athletes)`}</strong></p>
    <p>{`AMRAP 27:\\
69 Double Unders or Each do 69 Single Jump Ropes\\
4 Rope Climbs (15′) or modified Rope Climbs`}{`[\\
10 Clean & Jerks (135/95) or (95/65)\\
29 Wall Balls (20/14-10’/9′)\\
20 T2B or Toes to Hips\\
16 Burpee Box Jump Overs or Burpee Step Ups (24/20)]`}{`{.text_exposed_show}`}</p>
    <p><strong parentName="p">{`[Scaled Option 2: (This is a Scaled Option for participants who have
never done CrossFit before)]`}{`{.text_exposed_show}`}</strong></p>
    <p>{`[AMRAP 17:\\
69 Single Jump Ropes (Each)\\
4 modified Rope Climbs\\
10 KBS\\
29 Wall Balls (14/10-10’/9′)\\
20 Sit-ups\\
16 Burpee Step Ups (20″)]`}{`{.text_exposed_show}`}</p>
    <p>{`::: {.text_exposed_show}
We will have an “all stop” for two, 30 second and one 1 min, Memorial
breaks throughout the workout to remember Schweitz. At these times all
athletes will break, the music will stop and we will reflect on the Man
Jason was, his family that’s navigating through life without his
physical presence, and a community that lost a true hero and role model.
At 9:30 mark(Schweitz and Jess’ Wedding Anniversary 9/30/11), 35:17
(Jacksons Birth, 3/5/17) and 1 min at 28:14 (Averys Birth 12/8/14).
:::`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place next Saturday, November 4th!  There is still time to get a team
together. Truegritcomp.wordpress.com.  If you can`}<strong parentName="p">{`**`}</strong>{` help with
judging or in any other area please let us know.   There will be no
classes or open gym at either location this day.***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      